$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--squeeze" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="vh">Menú</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    // $("body").toggleClass("js-menu-open");
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("is-visible");
    } else {
      $(".scrolltop").removeClass("is-visible");
    }
  });

  // ----------------------
  // arreglar estils ul.rel
  // ----------------------
  $(".rel li a")
    .filter(function () {
      return (
        $(this).text() ==
        $(this)
          .parent()
          .text()
      );
    })
    .each(function (i, el) {
      $(el).addClass("onlyLink");
    });

  // ------
  // slider
  // ------
  $(".slider").slick({
    infinite: true,
    fade: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false
        }
      }
    ]
  });

  // ---------------
  // slider història
  // ---------------
  $('.slider-h').slick({
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  // --------
  // headroom
  // --------
  $(".main-header").headroom({
    offset: 205,
    tolerance: 5,
    classes: {
      initial: "animated",
      pinned: "slideDown",
      unpinned: "slideUp"
    }
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
                ? index + 1
                : e.which === 40
                  ? "down"
                  : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
                ? switchTab(e.currentTarget, tabs[dir])
                : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }
});

// -------
// toggles http://edenspiekermann.github.io/a11y-toggle/#connected-toggles
// -------
function collapse(toggle) {
  var collapsibleBox = document.getElementById(toggle.getAttribute('data-a11y-toggle'))
  collapsibleBox.setAttribute('aria-hidden', true)
  toggle.setAttribute('aria-expanded', false)
}

function collapseAll(event) {
  toggles
    .filter(function (t) {
      return t !== event.target || event.key === "Escape"
    })
    .forEach(collapse)
}

var toggles = Array.prototype.slice.call(
  document.querySelectorAll('.connected-toggles [data-a11y-toggle]')
)

toggles.forEach(function (toggle) {
  toggle.addEventListener('click', collapseAll)
})

//amagar toggles desplegats al clicar fora
$("body").click(function (e) {
  var a11yAttribute = $(e.target).attr("data-a11y-toggle");
  if (
    (typeof a11yAttribute === typeof undefined || a11yAttribute === false) &&
    $(e.target).closest("#buscador").length == 0 && $(e.target).closest("#area-privada").length == 0
  ) {
    toggles.forEach(collapse);
  }
});

$(document).keyup(function (e) {
  if (e.key === "Escape") { // escape key maps to keycode `27`
    collapseAll(e);
  }
});

// ----------
// Datepicker
// ----------
$(function () {
  $(".date").fdatepicker({
    format: "dd/mm/yyyy",
    language: "es",
    weekStart: 1,
    leftArrow:
      '<button type="button"><img src="/media/img/bt-calendario-anterior.png" srcset="/media/img/bt-calendario-anterior@2x.png 2x" width="24" height="24" alt="Mes anterior" /></button>',
    rightArrow:
      '<button type="button"><img src="/media/img/bt-calendario-siguiente.png" srcset="/media/img/bt-calendario-siguiente@2x.png 2x" width="24" height="24" alt="Mes siguiente" /></button>'
  });
});

// -----------
// Collapsible table
// -----------
$(".exploder").click(function(){

  $(this).closest("tr").next("tr").toggleClass("hide");

  if($(this).closest("tr").next("tr").hasClass("hide")){
    $(this).closest("tr").next("tr").children("td").slideUp();
  }
  else{
    $(this).closest("tr").next("tr").children("td").slideDown(350);
  }
});


// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll('.collapsible__title')

  Array.prototype.forEach.call(headings, heading => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false">
        ${heading.textContent}
        <svg aria-hidden="true" focusable="false" viewBox="0 0 14 10">
          <path fill-rule="evenodd" d="M0 0L7 10 14 0z"/>
        </svg>
        <svg aria-hidden="true" focusable="false" viewBox="0 0 75 75">
          <circle cx="36.5" cy="36.5" r="36.5"></circle>
          <rect class="vert" width="3" height="21" x="35" y="26"></rect>
          <rect width="21" height="3" x="26" y="35"></rect>
        </svg>
      </button>
    `
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = []
      while (elem.nextElementSibling && elem.nextElementSibling.className !== 'collapsible__title') {
        elems.push(elem.nextElementSibling)
        elem = elem.nextElementSibling
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node)
      })

      return elems
    }

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading)

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement('div')
    wrapper.hidden = true

    // Add each element of `contents` to `wrapper`
    contents.forEach(node => {
      wrapper.appendChild(node)
    })

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling)

    // Assign the button
    let btn = heading.querySelector('button')

    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute('aria-expanded') === 'true' || false

      // Switch the state
      btn.setAttribute('aria-expanded', !expanded)
      // Switch the content's visibility
      wrapper.hidden = expanded
    }
  })

  if ($('.collapsible__title').length > 0 && window.location.hash.indexOf('#entry-') > -1 && $(window.location.hash).length > 0) {
    $(window.location.hash).find('button').attr('aria-expanded', true);
    $(window.location.hash).next().show();
    $('html, body').animate({
        scrollTop: $(window.location.hash).parent().offset().top
    }, 1000);
  }

})();
